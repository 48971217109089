<template>
    <Layout>
      <Sider  hide-trigger :collapsed-width="64"  class="siderCls" v-if="isShow" style="background:#fff,width: 190px;min-width: 190px;max-width:190px;">  
            <div style="overflow:auto;margin-top:2px;" :style="{height:divHeight}">
                <template v-for="(item, index) in menuList" >                             
                    <div  class="oneMenu" :key="index" @click="topClick(item.code)"> {{item.cname}} <span style="color:#fff">({{item.cnt}})</span> </div>
                    <template v-for="(subitem ) in item.children">
                        <span :key="subitem.code" >
                            <div  class="twoMenu">
                                <a  :class="{active: active===subitem.code}" @click="menuClick(subitem.code)">
                                    <i class="iconfont icon-xiayiye1" style="font-size:10px;margin-right:5px;"></i>{{subitem.cname}} <span style="color:#606266">({{subitem.cnt}})</span>
                                </a>
                            </div>
                        </span>
                    </template>
                </template>  
            </div>
      </Sider>
        <Content>
                <div style="background:#fff; display:flex;line-height:30px;height:40px;margin-top:2px;" >
                    <div style="margin-top:5px;display:flex; ">
                        <el-button type="text" size="small" icon="iconfont icon-chenggong1" @click="selSure" style="color:#2db7f5;margin-top:-6px; margin-right:20px;margin-left:5px;">确定选择</el-button> 
                        <div style="margin-right:6px;font-size:12px;margin-left:5px;">状态</div>
                        <el-select v-model="stfgSet"   size="small" @change="stfgChange" style="width:110px">
                            <el-option label="所有产品" value="ALL"></el-option>
                            <el-option label="未审核的" value="N"></el-option>
                            <el-option label="已审核的" value="Y"></el-option>
                        </el-select>
                        <span style=" margin-left:10px;font-size:12px;">字段查询</span>
                        <el-input :placeholder="input_hold" v-model="textparam" @keyup.enter.native="qryMatData(selparam,textparam,'','','V_MAT_VALID')" size="small" style="width:350px;margin-left:10px;height:30px;">
                            <el-select v-model="selparam" slot="prepend" placeholder="请选择列名" clearable @change="elchange" style="width:140px; " >
                                <span v-for="item in matCol" :key='item.key'>
                                    <el-option :label="item.title" :value="item.key"></el-option>
                                </span>
                            </el-select>
                            <el-button @click="qryMatData(selparam,textparam,'','','V_MAT_VALID')" slot="append" icon="el-icon-search"></el-button>
                        </el-input> 
                    </div>
                </div>
                <el-table  :data="matData" 
                    border                            
                    size="small"
                    ref="refmat"
                    v-loading="matloading"
                    style="overflow:auto;margin-top:1px;"
                    element-loading-text="加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(255, 251, 255, 0.8)"  
                    @row-click="rowClick"
                    @row-dblclick="selSure"
                    :row-key="getRowKeys"
 
                    :height="subHeight" 
                    highlight-current-row> 
                    <span v-for="(item ) in matCol" :key="item.key" >
                    <el-table-column  v-if ="item.thide==='N' &&item.key!=='PIC'&&item.key!=='RN'&&item.key!=='CHKSTFG'"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        sortable
                        :min-width="item.width">
                    </el-table-column> 
                    <el-table-column  v-if="item.key==='CHKSTFG'"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :min-width="item.width">
                        <template slot-scope="scope" >
                            <el-checkbox v-model="scope.row[item.key]" disabled true-label="Y" false-label="N"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column  v-if ="item.key==='PIC'"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        sortable
                        :min-width="item.width">
                        <template slot-scope="scope" >
                            <div style=" width:50px;height:42px; " >
                                <el-tooltip v-if="scope.row.IMGPATH" effect="light"  placement="right"  popper-class="atooltip">
                                    <el-link  :underline="false">
                                        <el-image fit="fill" style="width:50px;height:40px;  " :src="scope.row.PIC"></el-image>
                                    </el-link>
                                    <template slot="content" >
                                        <el-image  fit="scale-down" style="width:250px;height:250px; text-align:center; " :src="scope.row.PIC"></el-image>    
                                    </template>
                                </el-tooltip>

                                <el-link  :underline="false" v-else >
                                    <el-image fit="scale-down" style="width:50px;height:40px; " :src="scope.row.PIC"></el-image>
                                </el-link>
                            </div>
                        </template>
                    </el-table-column> 
                    </span> 
                </el-table>
                <div style="margin-top: 2px;margin-bottom:2px;overflow: hidden;display:flex;background:white">
                    <div style="float: left;padding-left:5px;">
                        <Page :total="dataCount" :page-size="rowSize" show-total :current="1" @on-change="changePage"></Page>
                    </div>
                    <span style="color:#606266;margin:7px 10px;font-size:12px;">每页</span>
                    <el-select v-model="rowSize" size="small" style="width:70px;" @change="initPage">
                            <el-option 
                                v-for="item in pageList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                    </el-select>
                    <span style="color:#606266;margin:7px 10px;font-size:12px;">跳至</span>
                    <el-input v-enter-number size="small" v-model="goPage" style="width:50px;"></el-input> 
                    <span style="color:#606266;margin:7px 5px;font-size:12px;">页</span>
                    <el-button @click="goNext" size="small" type="primary" style="width:50px;margin-left:15px;height:35px;margin-right:50px;">GO</el-button>
 
                </div>  
        <el-dialog :visible.sync="dialogVisible" width="450px" append-to-body>
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">提示</span>
            </div>
            <div   >{{prompt}} </div>
            <span slot="footer"  :style="{'height':'40px' }">
                <el-button size="small" type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </Content>
    </Layout>
</template>
<script>
    import { getTabColOrData,getBaseData } from '../../api/user';
    import { paramData,paramBase ,getDate,paramData2 } from '../../api/Select'

    export default {
    data() {
        return {
            listurl:[],//放大的图片
            menuList:[],
 
            cfmprompt:'',
            dialogVisible:false,
            addModal:false,
 
            prompt:'',
            rowChk:'',
            addFlag:'产品详情',
            matloading:false,
            showViewer:false,//打开预览/关闭
            saveFlag:true,
            drawer:false,
            scale: 100, //放大系数
 
            loading:false,
            propData:[],
            cfmVal:'',
            paramFlag:'', // 产品参数标志：新增或修改
            specFlag:'',
            prdType:[], //产品库级联数据源
            tabFrmCol:[] ,//表单列集合
            rowSize:30,
            pageList: [{
                value: 30,
                label: '30'
                },  {
                value: 50,
                label: '50'
                } 
            ],
            pageNum:1,
            // 初始化信息总条数
            dataCount:0,
            prcModel:false,
 
            goPage:'',
            stfgSet:'ALL',
            videoSrc:'',
            paramRow:{},
            isShow:true,
            specArr:[],
 
            type:'create',
 
 
            imgSrc:'',
            LANG:'',
 
            treeData:[] ,//  树数据
            matCol:[], //产品列（表格隐藏列）
            matForm:[], //产品表单列（表单隐藏列）
 
 
 
            uploadData:{}, //上传数据
            upFile:{},
            matRow:{}, //产品行
 
            selparam:'IDNO',// select 绑定值
 
            textparam:'', // input 绑定值
            defaultProps: 
            {  //el-tree 默认字段属性
                children: 'children',
                id:'id',
                icon:'icon',
                label: 'label'
            },

            idseq:'-1',
            filterText: '',
            matData:[] , //产品数据
 
 
            allData:[] , //保存产品所有数据
 
 
            v_url:this.$store.state.queryUrl , //api请求路径     
            v_many:this.$store.state.manyUrl , //api请求路径   
 
            v_formurl:this.$store.state.baseUrl+'user/getFormGroup', //多记录查询 like模式    
            input_hold:'请输入内容...',
            input_hold2:'请输入内容...',
            matty:'', //产品类别，由数组变为字符串保存
 
            rowIndex :'-1',
            v_form:'base',
            colory:'#959997', //审核颜色
            colorx:'#e00e66', //作废颜色
 
            CHKSTFGNM:'',
 
            active:'',
 
            prdRow:'',
 
            qryFlag:false, //查看标志
            v_user:this.$cookies.get('v_username'), 
            v_pwd:this.$cookies.get('v_password'),
            v_idseq:this.$cookies.get('idseq'),
        }
    },
    watch: {
        // filterText(val) {
        //     this.$refs.eltree.filter(val);
        // }
    },
    components: {
 
    },
    computed: {
        divHeight: function() {
            return (window.innerHeight - 190) + 'px';
        }, 
        subHeight: function() {
            return (window.innerHeight - 200) + 'px';
        }, 
        BOXCUBE :function(){
            return (this.matRow['BOXL'] *this.matRow['BOXW']*this.matRow['BOXH'])/1000000000 ;
        }
    },
    mounted () {
        if (this.v_user){  
            this.getTabCol() //产品表列字段 
            this.qryMatData( this.selparam,this.textparam,'','','V_MAT_VALID')  
            this.getMenuList()  //左边菜单
 
        } else{
            let routeUrl = this.$router.resolve({
                path: "/frontLogin",
                query: { form:this.v_form },
            });
            window.open(routeUrl.href, '_self');
        }
    },
    created () {  
        this.v_form =this.$route.query.form
 
        getTabColOrData(this.v_formurl,this.v_user,this.v_pwd,'v_sys_form_fld','idseq','29','gpnm,gpsort','','','',' order by gpsort').then((res) => {
            this.tabFrmCol = res.data.result             
        }); 
 
 
 
        getBaseData(this.$store.state.nologinCasUrl, 'parentno','0','','','V_PRD_CATEGORY','').then(res=>{
            this.prdType=res.data
        })
           
    },
    methods: {
        selSure(){
            if (this.prdRow['MATNM']){
                this.$emit('child-prd', this.prdRow)
                this.$emit('child-mat',{IDNO:this.prdRow['IDNO'],CNAME:this.prdRow['MATNM'],MATTYNM:this.prdRow['MATTYNM'],MODELNM:this.prdRow['MODELNM'],MATDESC:this.prdRow['MATDESC'],
                TAXPRC:this.prdRow['TAXPRC'],IMGPATH:this.prdRow['IMGPATH'],ENAME:this.prdRow['ENAME'],KRENO:this.prdRow['KRECOPN']})
            }else{
                this.dialogVisible=true
                this.prompt='请选择一产品记录'
            }
        },
        //一级菜单点击
        topClick( code){
             this.qryMatData( 'chkstfg','%','matty',code,'V_MAT_VALID') 
        },
        //二级菜单点击
        menuClick( code){
            this.active =code
             this.qryMatData( 'chkstfg','%','MODELNO',code,'V_MAT_VALID') 
        },
        //左边导航菜单
        getMenuList(){
            this.$axios({
                method:'post',
                url: this.$store.state.baseUrl+'sysprivs/getMatMenu?username='+this.v_user +'&password='+this.v_pwd ,
                //data:{p_table:'v_bs_mat'}
            }).then(res =>{
                this.menuList =res.data        
            })
        },
 
 
        rowClick(row){
            let matty_=row.MATTY.toString()
            if(matty_!==''){
                Object.assign(row,{'MATTY':matty_.split(',')})
            } 
            this.prdRow =JSON.parse(JSON.stringify(row))
        },
        getRowKeys(row){
            return row.IDSEQ
        },
        //跳至页数
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.dialogVisible =true
                this.prompt='请输入跳至页数'
            }
        },
 
        //表行记录css
        tableRowClass({row, rowIndex}) {
            if (row.CHKSTFG  === 'Y') {
                return 'success-row';
            } else if (row.CHKSTFG ==='X') {
                return 'cancel-row';
            }
            return '';
        },
        // 查看
        qryRec(row){
                this.qryFlag=true
                this.drawer =true
 
                if (row.CHKSTFGNM==='审核')  {  
                    this.saveFlag=false
                    this.imgSrc='/assets/basedict/confirm.png'
                }else if (row.CHKSTFGNM ==='作废')  {  
                    this.saveFlag=false
                    this.imgSrc='/assets/basedict/cancel.png'
                }else{
                    this.imgSrc='/assets/basedict/no_confirm.png'
                    this.saveFlag=true
                }
                this.saveFlag=false
        },
 
 
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.matData = this.allData.slice(_start,_end);
        },
        //初始化文件页面
        initPage(){
            //产品明细
            this.dataCount =this.allData.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allData.length < this.rowSize){
                this.matData = this.allData          
            }else{
                this.matData =this.allData.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
        },
 
        modelChange(){
            this.$forceUpdate()
        },
        //状态查询
        stfgChange(){
            if (this.stfgSet==='ALL'){
                this.qryMatData( 'chkstfg','%','','','V_MAT_VALID')  
            }else{
                this.qryMatData( 'chkstfg',this.stfgSet,'','','v_bs_mat')  
            }
        },
 
        toggleLeft(){
            this.isShow =!this.isShow
        },
 
        //查询产品信息
        qryMatData( key,value,key2,value2,table){
            let vm=this
            this.matloading=true
            getTabColOrData(this.v_url,this.v_user,this.v_pwd,table,key2,value2,'','','','',' and '+key+' like \'%'+value+'%\' AND ROWNUM<100').then((res) => {
                this.matData=[]
                for (var k=0; k<res.data.length; k++){
                    let obj_={}
                    let row_=res.data[k]
                    let matty_=''
                    if (row_.MATTY){
                        matty_ =row_['MATTY'].split(',')
                    }
                    Object.assign(obj_,res.data[k],{'MATTY':matty_ })
                    vm.matData.push(obj_)
                } 
                //保存取到 所有数据
                vm.allData =vm.matData
                vm.dataCount =res.data.length //总条数
                // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
                if (res.data.length < vm.rowSize){
                    vm.matData = vm.allData
                }else{
                    vm.matData =vm.allData.slice(0,vm.rowSize) //返回选定元素的子数组，不包含结尾元素
                }
                vm.matloading=false 
            })
            
        },
 
        elchange(val){  // 这个val 就是 el-select 的 value
            let obj ={}
            obj =this.matCol.find(item=>{
                return item.key===val
            })
            if (obj)
            this.input_hold='请输入'+obj.title+' 的值'
        },
        //获取表列头字段
        getTabCol(){
            var vm=this
            //产品列
            getTabColOrData(vm.v_many,vm.v_user,vm.v_pwd,'v_sys_form_fld','idseq','29','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                vm.matForm = res.data.result
                for (let k=0; k<res.data.result.length; k++){
                    vm.matCol.push({  
                        title: res.data.result[k].title,
                        key: res.data.result[k].key,
                        align: res.data.result[k].align,
                        thide: res.data.result[k].THIDE,
                        fhide: res.data.result[k].FHIDE,
                        width: res.data.result[k].width,
                        sortable: res.data.result[k].sortable?true:false,
                    })
                }
            });
        },
 
        //节点过滤
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 获取 树形控件数据
 
    },
 }
</script>

<style scoped lang="less">
    .active { 
        color:#D200D2; 
        text-decoration:none; 
    } 
    .oneMenu{
        line-height:30px;
        text-align:left;
        padding-left:5px;
        font-size:10px;
        color:white;
        background-color: #409eff;
    }
    .twoMenu{
        display:flex;
        flex-direction:column;
        line-height:25px;
        background:white;
        padding-left:10px;
 
    }
    .twoMenu:hover{
        cursor: pointer;
        color:#409EFF;
    }
    .oneMenu:hover{
        cursor: pointer;
        color:#f4f6f8;
    }
    .el-cascader-menu{
        height:400px;
        width:100%;
    }
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
    .video-js .vjs-icon-placeholder {
        width: 80%;
        height: 80%;
        display: block;
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
</style> 
<style lang="less">
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    //.el-dialog__headerbtn i { background:white;font-size: 20px;  }
    .md-menu{margin-right: 15px;cursor: pointer}
    .md-menu:hover{background-color:#dae9f7 }
    .item-three  {
        width: 32%;
        color:#409EFF;
    }

    .row-width  {
        width: 98.5%;
    }
    .el-card__header{
        //height:35px;
        vertical-align: middle;
    }
    .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px;
            color:rgb(11, 11, 59);
            font-size:12px;
            background:rebeccapurple;
        }
        td {
            padding: 5px ;
            font-size:11px;
        } 
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
    .atooltip{
      border: #1480ce 1px solid !important;
    }
    .el-tooltip__popper[x-placement^=right] .popper__arrow::after {
      border-right-color: #1480ce !important;
    }
    .el-tooltip__popper[x-placement^=right] .popper__arrow{
        border-right-color: #1480ce !important;
    }
 
    .el-table .success-row {
        color:#959997;
    }
    .el-table .cancel-row {
        color: #e00e66;
    }
    .topback{
        border:1px solid #E4E7ED; 
        width:60px;
        height:60px;
        font-size:12px;
        color: #409EFF;
        text-align: center;    
        display:flex;
        flex-direction: column;
        position:fixed;
        right:0px;
        bottom:275px;
        background-color: rgb(255,255,253);
        cursor:pointer;
        z-index:999999;
    }
    .topfont{
        color:#409EFF;
        font-size:16px;
        // font-weight:600;
    }
    .topback:hover{
        background-color:#2b85e4;
        color:white;
    }
    .messageBox .el-message-box__content{
        border-top: 1px solid #EBEEF5;
        height: 50px;
    }
    .el-message-box__message p{
        line-height: 25px;
    }
    .messageBox .el-message-box__btns{
        padding: 20px 15px 0;
    }
    .messageBox .el-message-box__header{
        background:#409eff;
    }
    .messageBox .el-message-box__title{
        color:white ;
        font-size:14px;
    }
</style>
 
